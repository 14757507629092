<template>
<div class="page-privacy-wrap">
    <div class="page-privacy">
        <h1>个人信息保护制度</h1>
        <h2>第一章 总则</h2>
        <p><i>第一条</i>为了保护平台用户的合法权益，维护网络信息安全，根据《中华人民共和国个人信息保护法》和《互联网信息服务管理办法》等法律、行政法规，制定本规定。</p>
        <p><i>第二条</i>平台在提供互联网信息服务过程中收集、使用用户个人信息的活动，适用本规定。</p>
        <p><i>第三条</i>本规定所称用户个人信息，是指平台在提供服务的过程中收集的用户姓名、性别、出生日期、身份证件号码、住址、电话号码、账号和密码、快递地址等能够单独或者与其他信息结合识别用户的信息以及用户使用服务的时间、地点等信息。</p>
        <p><i>第四条</i>平台在提供服务的过程中收集、使用用户个人信息，应当遵循合法、正当、必要的原则。</p>
        <p><i>第五条</i>平台对在提供服务过程中收集、使用的用户个人信息的安全负责。</p>
        <h2>第二章 信息收集和使用规范</h2>
        <p><i>第六条</i>平台会制定用户个人信息收集、使用规则，并在平台上予以公布。</p>
        <p><i>第七条</i>未经用户同意，平台不会收集、使用用户个人信息。如确需用户提供个人信息，会明确告知用户收集、使用信息的目的、方式和范围，查询、更正信息的渠道以及拒绝提供信息的后果等事项。</p>
        <p><i>第八条</i>平台不会收集其提供服务所必需以外的用户个人信息或者将信息用于提供服务之外的目的，不会以欺骗、误导或者强迫等方式或者违反法律、行政法规以及双方的约定收集、使用信息。</p>
        <p><i>第九条</i>在用户终止使用平台服务后，平台会停止对用户个人信息的收集和使用，并为用户提供注销号码或者账号的服务。</p>
        <p><i>第十条</i>平台及平台工作人员对在提供服务过程中收集、使用的用户个人信息会严格保密，不会泄露、篡改或者毁损，不会出售或者非法向他人提供。</p>
        <p><i>第十一条</i>平台委托他人代理市场销售和技术服务等直接面向用户的服务性工作，涉及收集、使用用户个人信息的，会对代理人的用户个人信息保护工作进行监督和管理，不会委托不符合本规定有关用户个人信息保护要求的代理人代办相关服务。</p>
        <p><i>第十二条</i>平台会建立用户投诉处理机制，公布有效的联系方式，接受与用户个人信息保护有关的投诉，并自接到投诉之日起十五日内答复投诉人。</p>
        <h2>第三章 安全保障措施</h2>
        <p><i>第十三条</i>平台会采取以下措施防止用户个人信息泄露、毁损、篡改或者丢失：</p>
        <p>（1）确定各部门、岗位和分支机构的用户个人信息安全管理责任；</p>
        <p>（2）建立用户个人信息收集、使用及其相关活动的工作流程和安全管理制度；</p>
        <p>（3）对工作人员及代理人实行权限管理，对批量导出、复制、销毁信息实行审查，并采取防泄密措施；</p>
        <p>（4）妥善保管记录用户个人信息的纸介质、光介质、电磁介质等载体，并采取相应的安全储存措施；</p>
        <p>（5）对储存用户个人信息的信息系统实行介入审查，并采取防入侵、防病毒等措施；</p>
        <p>（6）记录对用户个人信息进行操作的人员、时间、地点、事项等信息；</p>
        <p>（7）按照信息安全相关规定开展通信网络安全防护工作；</p>
        <p>（8）与信息安全相关的其他必要措施。</p>
        <p><i>第十四条</i>平台保管的用户个人信息发生或者可能发生泄露、毁损、丢失的，会立即采取补救措施；造成或者可能造成严重后果的，会立即向准予其许可或者备案的管理小组报告，配合相关部门进行的调查处理。</p>
        <p><i>第十五条</i>平台会对平台工作人员进行用户个人信息保护相关知识、技能和安全责任培训。</p>
        <p><i>第十六条</i>平台会对用户个人信息保护情况每年至少进行一次自查，记录自查情况，及时消除自查中发现的安全隐患。</p>
        <h2>第四章 监督检查</h2>
        <p><i>第十七条</i>平台会成立监督小组对平台保护用户个人信息的情况实施监督检查。监督小组实施监督检查时，可以要求平台提供相关材料，进入其生产经营场所调查情况，平台工作人员应当予以配合。监督小组实施监督检查，应当记录监督检查的情况，不得妨碍平台正常的经营或者服务活动，不得收取任何费用。</p>
        <p><i>第十八条</i>监督小组对在履行职责中知悉的用户个人信息应当予以保密，不得泄露、篡改或者毁损，不得出售或者非法向他人提供</p>
        <p><i>第十九条</i>平台实施业务经营许可及经营许可证年检时，应当对用户个人信息保护情况进行审查。</p>
        <p><i>第二十条</i>平台会依法制定有关用户个人信息保护的自律性管理制度，引导工作人员加强自律管理，提高用户个人信息保护水平。</p>
    </div>
</div>
</template>
<script>
export default {
    name: 'UserPrivacy',
}
</script>
<style lang="stylus" scoped>
.page-privacy-wrap
    background  #fff
    padding-bottom 60px
    .page-privacy
        width 1200px
        margin 0 auto
        h1
            font-size 28px
            line-height 40px
            margin-bottom 24px
            padding-top 84px
            text-align center
        h2
            font-size 28px
            padding-top:32px;
            line-height 40px
            margin-bottom 24px
            text-align center
        p
            line-height 48px
            font-size 20px
            color #000
            text-indent:40px;
            i{
                font-weight:550;
                font-style:normal;
                margin-right:10px;
            }
</style>